const sliders = document.querySelectorAll(".slider");

sliders.forEach((slider) => {
  const slides = slider.querySelectorAll(".slide");
  let currentSlideIndex = 0;

  setInterval(() => {
    const currentSlide = slides[currentSlideIndex];
    currentSlide.classList.remove("current");
    currentSlideIndex = (currentSlideIndex + 1) % slides.length;
    const nextSlide = slides[currentSlideIndex];
    nextSlide.classList.add("current");
  }, 5000);
});
